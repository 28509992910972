import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, StatusLabel, Typography } from '../../../../../Components'
import { useTranslationDecorator } from '../../../../../Hooks/useTranslationDecorator'
import { formatDateString } from '../../../../../Lib/Formatters/DateFormatter'
import { InviteBudgetGroups } from './Components/InviteBudgetGroups'
import { ProgressBlock } from './Components/ProgressBlock'
import { SurveyResponseBudgetGroups } from './Components/SurveyResponseBudgetGroups'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const ProgressInvitedEmployees: React.FC<IProps> = ({
  referenceDate,
  statistics,
  isDataDefinitive,
  hasOpenSurveys,
  budgetGroups,
}): JSX.Element => {
  const { t } = useTranslation()
  const { locale } = useTranslationDecorator()
  const hasInvites = hasOpenSurveys
  const [showInviteBudgetGroups, setShowInviteBudgetGroups] = useState<boolean>(false)
  const [showSurveyResponse, setShowSurveyResponse] = useState<boolean>(false)

  const getRespondentsAndInvitees = (respondents: number | null, invitees: number | null): string | null => {
    if (!respondents && !invitees) return null

    return `${respondents}/${invitees}`
  }

  const getStatusLabel = (): JSX.Element | null => {
    switch (true) {
      case isDataDefinitive:
        return <StatusLabel variant="gray" label={t('Closed')} />
      case statistics.thresholdMet:
        return <StatusLabel variant="green" label={t('Goal met')} />
      case hasInvites:
        return <StatusLabel variant="orange" label={t('Invited')} />
      default:
        return null
    }
  }

  return (
    <React.Fragment>
      <div className={Styles.container}>
        <div className={Styles.wrapper}>
          <div className={Styles.header}>
            <Typography variant="h1" className={Styles.noMargin}>
              {t('Traveled kilometers employees')}
            </Typography>

            {getStatusLabel()}
          </div>

          {!hasInvites && (
            <Typography variant="body-2">{t('Invite employees to estimate their traveled kilometers.')}</Typography>
          )}

          {hasInvites && (
            <div className={Styles.progressBlocksWrapper}>
              <ProgressBlock
                title={t('Last invitation')}
                body={
                  statistics.lastInvitationDate
                    ? `${formatDateString(statistics.lastInvitationDate, 'd MMMM Y', locale)}`
                    : ''
                }
              />

              {statistics.lastReminderDate && (
                <ProgressBlock
                  title={t('Last reminder')}
                  body={formatDateString(statistics.lastReminderDate, 'd MMMM Y', locale)}
                />
              )}

              <ProgressBlock
                title={t('Number invited')}
                body={statistics.numInvitees ? `${statistics.numInvitees}` : ''}
              />

              <ProgressBlock
                title={t('Number of respondents')}
                body={
                  <div className={Styles.respondentsWrapper}>
                    <Typography
                      variant="body-2"
                      className={`${Styles.respondentsStatus} ${statistics.thresholdMet ? Styles.thresholdMet : ''} `}
                    >
                      {getRespondentsAndInvitees(statistics.numRespondents, statistics.numInvitees)}
                    </Typography>

                    <Typography
                      variant="body-2"
                      className={Styles.link}
                      onClick={(): void => setShowSurveyResponse(true)}
                    >
                      {t('Details_short')}
                    </Typography>
                  </div>
                }
              />
            </div>
          )}
        </div>

        {!isDataDefinitive && (
          <Button
            size="large"
            variant="secondary"
            className={Styles.inviteButton}
            onClick={(): void => setShowInviteBudgetGroups(true)}
          >
            {t('Invite employees')}
          </Button>
        )}
      </div>

      {showInviteBudgetGroups && budgetGroups && (
        <InviteBudgetGroups
          budgetGroups={budgetGroups}
          referenceDate={referenceDate}
          onClose={(): void => setShowInviteBudgetGroups(false)}
        />
      )}

      {showSurveyResponse && (
        <SurveyResponseBudgetGroups budgetGroups={budgetGroups} onClose={(): void => setShowSurveyResponse(false)} />
      )}
    </React.Fragment>
  )
}
