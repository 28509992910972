import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { Button } from '../../../../../Components/Button'
import { ConfirmModal } from '../../../../../Components/ConfirmModal'
import { useCloseWrpmSurveyMutation } from '../../../../../Lib/graphql'
import { IProps } from './interfaces'

export const FinalizeButton: React.FC<IProps> = ({ organizationId, year }): JSX.Element => {
  const { t } = useTranslation()
  const [showFinalizeConfirmModal, setShowFinalizeConfirmModal] = useState<boolean>(false)
  const [closeWrpmSurveyMutation] = useCloseWrpmSurveyMutation()

  const handleClick = (): void => {
    closeWrpmSurveyMutation({
      variables: { input: { organizationId, year } },
      refetchQueries: ['getBudgetGroups', 'getWrpm'],
    })
      .then(() => setShowFinalizeConfirmModal(false))
      .catch(() => {
        toast.error(t('An error has occurred'))
      })
  }

  return (
    <React.Fragment>
      <Button variant="primary" size="large" onClick={(): void => setShowFinalizeConfirmModal(true)}>
        {t('Finalize')}
      </Button>

      {showFinalizeConfirmModal && (
        <ConfirmModal
          content={t('Finalize')}
          description={t(
            'This will save the data, and all invited employees who have not yet filled in their travel data will receive an email notifying them that the survey has been closed. This action is irreversible.'
          )}
          cancelContent={t('Cancel')}
          onCancel={(): void => setShowFinalizeConfirmModal(false)}
          confirmButtonProps={{
            content: t('Finalize'),
            onClick: handleClick,
          }}
        />
      )}
    </React.Fragment>
  )
}
