import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import { toast } from 'react-toastify'

import { Button, SideDrawer, Typography } from '../../../../../Components'
import { Radio } from '../../../../../Components/FormComponents/Radio'
import { Option } from '../../../../../Components/FormComponents/Radio/Components/Option'
import { Sheet } from '../../../../../Components/SideDrawer/components/Sheet'
import {
  ITravelPurposeEnum,
  IUpdateInput,
  IWrpmModalityEnum,
  IWrpmSourceEnum,
  IWrpmSurveySourceEnum,
  useGetWrpmLazyQuery,
  useUpdateWrpmSurveyMutation,
} from '../../../../../Lib/graphql'
import { ManualEditForm } from './Components/ManualEditForm'
import { transformData } from './helpers'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const EditBudgetGroupSource: React.FC<IProps> = ({
  budgetGroup,
  organizationId,
  data,
  year,
  onClose,
}): JSX.Element => {
  const defaultSource = budgetGroup.openWrpmSurvey?.source || IWrpmSurveySourceEnum.REISBALANS
  const { t } = useTranslation()
  const [showSideDrawer, setShowSideDrawer] = useState<boolean>(true)
  const [selectedSource, setSelectedSource] = useState<IWrpmSurveySourceEnum>(defaultSource)
  const previousSelectedSource = useRef<IWrpmSurveySourceEnum>(defaultSource)
  const [formData, setFormData] = useState<typeof data>(data)
  const [updateWrpmSurvey] = useUpdateWrpmSurveyMutation()
  const [getWrpm, { loading: wrpmReisbalansLoading }] = useGetWrpmLazyQuery()

  const handleManualEditFormChange = (
    modality: IWrpmModalityEnum,
    travelPurpose: ITravelPurposeEnum,
    value: string
  ): void => {
    setFormData((currentFormData) => {
      const returnData = structuredClone(currentFormData) || []
      const modalityRecord = returnData.find((record) => record.modality?.value === modality)

      if (modalityRecord?.statsPerModalityCommute && travelPurpose === ITravelPurposeEnum.COMMUTE)
        modalityRecord.statsPerModalityCommute.totalKms = value

      if (modalityRecord?.statsPerModalityBusiness && travelPurpose === ITravelPurposeEnum.BUSINESS)
        modalityRecord.statsPerModalityBusiness.totalKms = value

      return returnData
    })
  }

  const handleSourceChange = (value: string): void => {
    setSelectedSource(value as IWrpmSurveySourceEnum)

    if (previousSelectedSource.current !== value) {
      const isSwitchingFromSurveyToManual =
        previousSelectedSource.current === IWrpmSurveySourceEnum.SURVEY && value === IWrpmSurveySourceEnum.MANUAL

      if (isSwitchingFromSurveyToManual) {
        getWrpm({
          variables: {
            organizationId,
            budgetGroupNames: [budgetGroup.name!],
            forYear: year,
            sourceOverride: IWrpmSourceEnum.REISBALANS,
          },
        })
          .then((response) => {
            setFormData(response.data?.reisbalansV2.wrpm)
          })
          .catch(() => {
            toast.error(t('An error has occurred'))
          })
      }

      previousSelectedSource.current = value as IWrpmSurveySourceEnum
    }
  }

  const handleSaveButtonClick = (): void => {
    let surveyKms: IUpdateInput['surveyKms'] = []

    if (selectedSource === IWrpmSurveySourceEnum.MANUAL) surveyKms = transformData(formData)

    updateWrpmSurvey({
      variables: {
        input: {
          budgetGroupName: budgetGroup.name || '',
          organizationId,
          year,
          source: selectedSource as unknown as IWrpmSourceEnum,
          ...(surveyKms!.length && { surveyKms }),
        },
      },
      refetchQueries: ['getBudgetGroups'],
    })
      .then(() => {
        setShowSideDrawer(false)
      })
      .catch(() => {
        toast.error(t('An error has occurred'))
      })
  }

  const renderManualEditForm = (): JSX.Element | null => {
    if (wrpmReisbalansLoading) {
      return (
        <div>
          <Skeleton width={300} />
          <Skeleton width={260} />
          <Skeleton width={360} />
        </div>
      )
    }

    return <ManualEditForm data={formData} onChange={handleManualEditFormChange} />
  }

  return (
    <SideDrawer
      title={t('Edit data')}
      subTitle={budgetGroup.name || ''}
      size="xl"
      show={showSideDrawer}
      onClose={onClose}
    >
      <Sheet>
        <div className={Styles.section}>
          <Typography variant="body-2" className={Styles.body}>
            {t('Select the correct dataset for this budget group, or modify it manually.')}
          </Typography>

          <Radio id="source" className={Styles.source} value={selectedSource} onChange={handleSourceChange}>
            <Option label={t('Travel balance data')} value={IWrpmSurveySourceEnum.REISBALANS} />

            {budgetGroup.openWrpmSurvey?.inviteSentAt !== null ? (
              <Option
                label={t('Employee survey')}
                value={IWrpmSurveySourceEnum.SURVEY}
                description={t('Data is extrapolated within the respective budget group.')}
              />
            ) : null}

            <Option
              label={t('Manually adjust')}
              value={IWrpmSurveySourceEnum.MANUAL}
              description={t('Pre-filled Travel balance data can be modified below.')}
            />
          </Radio>

          <div className={Styles.buttonsWrapper}>
            <Button variant="secondary" onClick={(): void => setShowSideDrawer(false)}>
              {t('Cancel')}
            </Button>

            <Button onClick={handleSaveButtonClick}>{t('Save')}</Button>
          </div>

          {selectedSource === IWrpmSurveySourceEnum.MANUAL && renderManualEditForm()}
        </div>
      </Sheet>
    </SideDrawer>
  )
}
